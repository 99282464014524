/* You can add global styles to this file, and also import other style files */

@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica.ttf");
}

@font-face {
  font-family: "Helvetica-Bold";
  src: url("./assets/fonts/Helvetica-Bold.ttf");
}

@font-face {
  font-family: "Helvetica-light";
  src: url("./assets/fonts/helvetica-light-587ebe5a59211.ttf");
}

body {
  font-family: "Helvetica" !important;
}

.iconSize30 {
  width: 30px;
  height: 30px;
  padding: 5px;
}

.iconSize40 {
  width: 40px;
  /* height: 40px; */
  /* padding: 5px; */
}

.cursor {
  cursor: pointer;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

label {
  margin-bottom: -10px !important;
}

.newButtonAlignrigt {
  text-align: right;
  margin-bottom: 10px;
}

.requiredMark::after {
  content: "*";
  color: red;
  margin-left: 5px;
}

.borderBox-themecolor {
  border: 1px solid #e80188 !important;
  padding: 5px !important;
  border-radius: 10px !important;
  margin-bottom: 7px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.borderBox {
  border: 1px solid #dddddd !important;
  padding: 6px !important;
  border-radius: 10px !important;
  margin-bottom: 7px !important;
}

.themeButton {
  background: #e80188 !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0 4% !important;
  border-radius: 34px !important;
  font-size: 1em !important;
}

.themeButtonSquare {
  background: #e80188 !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0 4% !important;
  border-radius: 5px !important;
  font-size: 1em !important;
}

.themeButtonSquareBlue {
  background: #3db7e4 !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0 4% !important;
  border-radius: 5px !important;
  font-size: 1em !important;
}
.themeButtonSquareOrange {
  background: orange !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0 4% !important;
  border-radius: 5px !important;
  font-size: 1em !important;
}
.themeButtonLarge {
  background: #e80188 !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0 2.75% !important;
  border-radius: 34px !important;
  font-size: 1em !important;
}

.themeButtonAsh {
  background: #455a64 !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0 4% !important;
  border-radius: 34px !important;
  font-size: 1em !important;
}

.themeButtonWhite {
  background: white !important;
  color: #e80188 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0 4% !important;
  border-radius: 34px !important;
  font-size: 1em !important;
  border: 1px solid #e80188 !important;
}

.pl-0 {
  padding-left: 0;
}

dx-text-box,
dx-select-box,
dx-drop-down-box,
dx-date-box,
dx-number-box,
dx-tag-box,
dx-text-area {
  border-radius: 33px !important;
}

.content-on-middle {
  max-width: 480px !important;
  margin: 0 auto !important;
  /* min-height: 100vh; */
  width: 100% !important;
}

.addNewBtn_roundshape_theamColor {
  background: #e80188;
  border-radius: 21px;
  padding: 1px 10px;
  color: white;
  cursor: pointer;

  border: 1px solid #dfdfdf;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding-bottom: 6px;
  padding-inline: 10px;
}

.addNewBtn_roundshape {
  background: white;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  color: #757272;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 21px;
  padding-bottom: 6px;
  padding-inline: 10px;
}

.addNewBtn_roundshape_disable {
  border: 1px solid #dfdfdf;
  color: #757272;
  background: #e4e4e4;
  cursor: not-allowed;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background: #e4e4e4;
  border-radius: 21px;
  padding-bottom: 6px;
  padding-inline: 10px;
}

.menuIcon {
  width: 20px;
  margin-right: 0.6rem;
}

.dropdownIcon {
  font-size: 20px !important;
  position: relative !important;
  /* top: 6px !important; */
}

.dx-datagrid-headers {
  padding-right: 0px !important;
  border: none !important;
  /* border-top: 0px !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important; */
  /* background: #F5F5F5 !important; */
  font-weight: bold !important;
  font-size: 16px !important;
}

/* .dx-row td {
  background-color: white !important;
}*/
.dx-header-row td {
  background-color: #3db7e4 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 14px !important;
  border-left: 1px white solid !important;
}

.dx-header-row td .dx-header-filter {
  color: white;
  position: relative;
  top: 2px;
  left: 1px;
  font-size: 12px;
}

.dx-row-alt td {
  background-color: #c4e8fc !important;
}

.custom-row {
  text-align: center;
}

.cal-week-view .cal-time-events,
.cal-week-view .cal-time-label-column {
  display: none !important;
}

.cal-week-view .cal-day-headers {
  padding-left: 0;
}

.cal-week-view .cal-events-row {
  margin-left: 0 !important;
}

.dx-datagrid-export-button .dx-icon-export-excel-button:before,
.dx-datagrid-export-menu .dx-icon-xlsxfile:before {
  content: "\f022" !important;
}

.dx-scrollable-scroll,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover {
  height: 15px !important;
}

.dx-popup-normal {
  background: rgb(173, 222, 242) !important;
  color: #d10074;
}

.dx-popup-title {
  color: #d10074 !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle !important;
}

.dx-datagrid .dx-row > td,
.dx-datagrid .dx-row > tr > td {
  padding: 3px !important;
}

.dx-datagrid-content table {
  border-bottom: 2px #3db7e4 solid !important;
}

.dx-button-mode-contained .dx-icon:hover {
  color: #e80188 !important;
}

.dx-button-mode-contained .dx-icon {
  font-size: 18px !important;
}

#formModule .dx-invalid {
  border-color: rgb(241 7 0);
}

.dx-overlay-wrapper {
  /* z-index: 999 !important; */
}

/*  */

/* .popup-body {
  padding-inline: 25px !important;
  padding-bottom: 25px !important;
  padding-top: 15px;
  background-color: rgb(173, 222, 242);
} */
.modal-label {
  font-size: 15px;
  text-decoration-color: black;
}

.dx-popup-normal {
  color: black !important;
}

.dx-popup-content {
  padding: 0px !important;
  text-decoration-color: black !important;
}

.dx-popup-title {
  display: flex !important;
  align-items: center !important;
  min-height: 40px !important;
  padding: 0px 16px !important;
  border-bottom: 3px solid rgb(83, 204, 236) !important;
  background: rgb(173, 222, 242) !important;
  font-weight: 500 !important;
}

.dx-toolbar-button.dx-toolbar-item-content.dx-button-has-text {
  background: none !important;
  border: none !important;
  color: black !important;
  font-size: 14px !important;
}

.dx-loadindicator {
  width: 20px !important;
  height: 20px !important;
}

.dx-item-content.dx-toolbar-item-content
  > .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text {
  border: 1px solid rgb(102, 102, 102) !important;
  border-radius: 4px !important;
}

.dx-searchbox.dx-icon-search {
  padding: 7px !important;
}

.dx-item-content.dx-toolbar-item-content
  > .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text.dx-button-has-icon.dx-datagrid-export-button {
  border: 1px solid rgb(221, 221, 221) !important;
}

.dx-dropdowneditor-input-wrapper.dx-selectbox-container {
  padding-left: 15px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.dx-texteditor-buttons-container {
  position: relative;
  right: 6px;
}

.dx-scrollable-scrollbar.dx-widget.dx-scrollbar-vertical.dx-scrollbar-hoverable
  > .dx-scrollable-scroll
  > .dx-scrollable-scroll-content {
  background-color: gray !important;
}


.dx-texteditor.dx-invalid .dx-texteditor-input-container::after, .dx-texteditor.dx-valid .dx-texteditor-input-container::after {
  right: 13px !important;
}


body.swal2-toast-shown .swal2-container.swal2-top-end {
  z-index: 999999;
}

.swal2-container{
  z-index: 999999 !important;
}
.swal2-overlay {
  z-index: 9999 !important;
}

.swal2-modal {
  z-index: 10000 !important;
}
